<template>
  <div class="mt-5 is-flex is-justify-content-space-between">

    <div class="has-background-white px-4 py-4 main-inner-set mr-4">
      <div class="mb-5">
        <p class="is-uppercase heading-text-size has-text-grey has-text-weight-medium mb-3">Редактировать Компанию</p>
        <small>Введите данные</small>
      </div>

      <b-field
        label="Тип"
        class="mt-3"
      >
        <b-select
          placeholder="Выберите компанию"
          :value="companyType"
          v-model="companyType"
        >
          <option
            v-for="(n,idx) in Data_type"
            :value="n"
            :key="idx"
          >
            {{ n }}
          </option>
        </b-select>
      </b-field>

      <label>
        <b><small>Наименование</small></b>
      </label>
      <input
        class="input mb-2"
        v-model="CompanyData.name"
        type="text"
        placeholder="Название компании"
      >
      <label>
        <b><small>БИН</small></b>
      </label>
      <input
        class="input mb-2"
        v-model="CompanyData.bin"
        type="text"
        placeholder="Бин компании"
      >
      <div class="mt-5">
        <button
          class="button is-info"
          @click="SaveChangesCom"
        >Сохранить
        </button>
        <button class="button is-warning mx-3">Деактивировать</button>
        <button
          class="button is-danger"
          @click="DeleteCompany"
        >Удалить
        </button>
      </div>
    </div>

    <div class="has-background-white px-4 py-4 main-inner-set">
      <h4 class="heading is-size-6 has-text-weight-medium">список филиалов</h4>
      <ul
        class="list"
        v-for="item of BranchesData"
        :key="item.id"
      >
        <li class="ml-3">
          <a @click="EditBranche(item.id)">{{ item.name }}</a>
        </li>
      </ul>
      <router-link
        class="has-text-weight-medium"
        to="/filials-registration"
      >Зарегистрировать филиал
      </router-link>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      CompanyData: [],
      company_id: null,
      companyType: null,
      BranchesData: [],
      Data_type: [],
    };
  },
  created() {
    this.getCompanyData();
    this.GetCompanyBranches();
    this.GetDataToCreate();
  },
  methods: {
    async getCompanyData() {
      this.company_id = this.$router.history.current.params.id.replace(/[^0-9]/g, "");
      await this.$axios.get(`admin/companies/${this.company_id}`).then(response => {
        this.CompanyData = response.data.data;

        this.companyType = this.CompanyData.type;
      });
    },
    async DeleteCompany() {
      await this.$axios.delete(`admin/companies/${this.company_id}`).then(response => {
        this.$router.push("/company-list");
      });
    },
    async SaveChangesCom() {
      let data = {
        "name": this.CompanyData.name,
        "bin": this.CompanyData.bin,
        "type": this.companyType,
      };

      await this.$axios.put(`admin/companies/${this.company_id}`, data).then(response => {
        if (response.data.code == 201) {
          this.$router.push("/company-list");
        }
      });
    },
    async GetCompanyBranches() {
      await this.$axios.get(`admin/companies/${this.company_id}/branches`).then(response => {
        this.BranchesData = response.data.data;
      });
    },
    EditBranche(e) {
      this.$router.push(`/filial-editor/${e}`);
    },
    async GetDataToCreate() {
      await this.$axios.get(`admin/companies/create`).then(response => {
        this.Data_type = response.data.data.types;
      });
    },
  },
};
</script>

<style>
.main-inner-set {
  flex: 1;
}

.list {
  list-style: disc;
}

.heading-text-size {
  font-size: 14px;
}
</style>
